<template>
  <v-container id="signup" fluid tag="section" style="padding-bottom: 150px">
    <section>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Sign Up for Free</div>
            </template>

            <validation-observer v-slot="{ invalid }">
              <v-form @submit.prevent="signUp">
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-text-field v-model="name" type="text" label="Name" />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="email"
                          type="email"
                          label="Email Address"
                        />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required|min:6"
                      >
                        <v-text-field
                          v-model="password"
                          type="password"
                          label="Password"
                        />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-btn
                        color="primary"
                        class="ml-0"
                        :disabled="invalid"
                        type="submit"
                      >
                        <v-icon class="mr-2" dark> mdi-send </v-icon>
                        Sign Up for Free
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
          </base-material-card>
        </v-col>
      </v-row>
    </section>
    <v-dialog v-model="showThankyouPopup" width="500">
      <v-card>
        <v-card-title class="text-h5 primary">
          <p style="color: #ffffff">Thank You!</p>
        </v-card-title>
        <v-card-text>
        <p>
          Thank you for signing up to PropCheck! Please check your emails 
          for a verification link to complete your registration.
        </p>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="closeDialog"> OK </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { ValidationProvider } from "vee-validate";

const auth = firebase.auth();

export default {
  name: "Signup",
  components: {
    ValidationProvider,
  },
  data: () => ({
    name: null,
    password: null,
    email: null,
    showThankyouPopup: false,
  }),
  methods: {
    closeDialog: function () {
      this.showThankyouPopup = false;
      if (process.env.VUE_APP_SOFTLAUNCH_MODE === "true") {
        // redirect here
        window.location.href = "https://propcheck.com.au/coming-soon/?avia_forced_reroute=1";
      } else {
        this.$router.push({ path: "/" });
      }
    },
    signUp: function (e) {
      this.$store
        .dispatch("signup", {
          name: this.name,
          email: this.email,
          password: this.password,
        })
        .then(() => {
          if (auth.currentUser) { 
            auth.currentUser.sendEmailVerification({
              url: window.location.origin + "/#/pages/user",
            });
            this.showThankyouPopup = true;
          }
        });
    },
  },
};
</script>
